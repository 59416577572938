import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { ClienteService } from '../../core/services/clientes/cliente.service';
import { ValidacaoFormularioService } from '../../core/tools/validacao-formulario.service';

@Component({
  selector: 'app-configuracoes',
  templateUrl: './configuracoes.component.html',
  styleUrls: ['./configuracoes.component.css']
})

export class ConfiguracoesComponent implements OnInit {
  /* Formulário */

  formDados: FormGroup;

  enviado: boolean = false;

  /* Modal */

  @ViewChild("configuracoes", { static: false }) configuracoes: ElementRef;

  /* Storage */

  b2b_margem_consumidor: number = parseFloat(localStorage.getItem("b2b_margem_consumidor"));

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private clienteService: ClienteService,
    private validacaoFormularioService: ValidacaoFormularioService,
  ) { }

  ngAfterViewInit() {
    this.modalConfiguracoes(this.configuracoes);
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnInit() {
    this.formDados = this.formBuilder.group({
      percentual_margem: [this.b2b_margem_consumidor, this.validacaoFormularioService.validarPorcentagemComZero]
    });
  }

  get f() {
    return this.formDados.controls;
  }

  /* Modal */

  modalConfiguracoes(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-configuracoes",
      size: "xl",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Cancelar") {
        this.router.navigate(["/produtos"]);
      } else if (acao == "Salvar") {
        this.enviado = true;

        if (this.formDados.invalid) {
          this.modalConfiguracoes(content);
        } else {
          let percentualMargem: number = this.formDados.controls["percentual_margem"].value == null || this.formDados.controls["percentual_margem"].value == "" ? 0 : parseFloat(this.formDados.controls["percentual_margem"].value);

          let alterarPercentualMargem: boolean = false;

          this.clienteService.updateB2BMargemConsumidor(percentualMargem).subscribe(
            alterarPercentualMargens => alterarPercentualMargem = alterarPercentualMargens,
            error => { console.log("Erro: " + error) },
            () => {
              if (alterarPercentualMargem) {
                localStorage.setItem("b2b_margem_consumidor", percentualMargem.toString());

                this.toastr.success("", "Configuração salva com sucesso!");

                this.router.navigate(["/produtos"]);
              }
            }
          );
        }
      }
    }, (reacao) => {
      if (reacao != null) {
        this.router.navigate(["/produtos"]);
      }
    });
  }
}

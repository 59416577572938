import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Pedido } from '../../models/pedidos/pedido';
import { PedidoBonificacaoRetorno } from '../../models/pedidos/pedido-bonificacao-retorno';
import { PedidoPagamento } from '../../models/pedidos/pedido-pagamento';
import { PedidoSintetico } from '../../models/pedidos/pedido-sintetico';

@Injectable({
  providedIn: 'root'
})

export class PedidoService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public add(pedido: Pedido): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "pedido": pedido
    };

    return this.http.post<boolean>(this.baseUrl + "api/Pedido/Add", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getByOrder(order_id_app: number, seller_id: number): Observable<Pedido> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "order_id_app": order_id_app,
      "seller_id": seller_id
    };

    return this.http.post<Pedido>(this.baseUrl + "api/Pedido/GetByOrder", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getProximoNumeroPedidoBySeller(): Observable<number> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "seller_id": 130
    };

    return this.http.post<number>(this.baseUrl + "api/Pedido/GetProximoNumeroPedidoBySeller", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(0);
      })
    );
  }

  /* Pagamento */

  public getPedidoPagamentoById(id: number): Observable<PedidoPagamento> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "id": id
    };

    return this.http.post<PedidoPagamento>(this.baseUrl + "api/Pedido/GetPedidoPagamentoById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  /* Sintético */

  public getB2BByCliente(client_id_app: number): Observable<PedidoSintetico[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app
    };

    return this.http.post<PedidoSintetico[]>(this.baseUrl + "api/Pedido/GetB2BByCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Ações */

  public enviar(order_id_app: number, percentual_desconto: number): Observable<PedidoBonificacaoRetorno> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "order_id_app": order_id_app,
      "seller_id_logado": localStorage.getItem("seller_id"),
      "seller_id_pedido": localStorage.getItem("seller_id"),
      "percentual_desconto": percentual_desconto
    };

    return this.http.post<PedidoBonificacaoRetorno>(this.baseUrl + "api/Pedido/Enviar", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }
}

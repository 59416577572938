import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class ValidacaoFormularioService {
  constructor() { }

  /* Porcentagem */

  validarPorcentagemComZero(controle: FormControl) {
    let controleValor: any = controle.value;

    if (controleValor != null) {
      if (controleValor < 0 || controleValor > 100) {
        return { invalid: true };
      }
    }
  }
}

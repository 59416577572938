import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { NotaFiscal } from '../../models/notas-fiscais/nota-fiscal';

@Injectable({
  providedIn: 'root'
})

export class NotaFiscalService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  /* Integração */

  public getIntegracaoNotaFiscalByNota(nota_fiscal: number, serie: string): Observable<NotaFiscal> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "nota_fiscal": nota_fiscal,
      "serie": serie,
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<NotaFiscal>(this.baseUrl + "api/NotaFiscal/GetIntegracaoNotaFiscalByNota", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }
}

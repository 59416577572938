import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Cliente } from '../../models/clientes/cliente';
import { ClienteEmpresa } from '../../models/clientes/cliente-empresa';
import { ClienteLocalEntrega } from '../../models/clientes/cliente-local-entrega';

@Injectable({
  providedIn: 'root'
})

export class ClienteService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  /* Clientes */

  public getById(client_id_app: number): Observable<Cliente> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": client_id_app
    };

    return this.http.post<Cliente>(this.baseUrl + "api/Cliente/GetById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public getByLogin(client_document: string, guidclientenapis: string, password_b2b: string): Observable<Cliente> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_document": client_document,
      "guidclientenapis": guidclientenapis,
      "password_b2b": password_b2b
    };

    return this.http.post<Cliente>(this.baseUrl + "api/Cliente/GetByLogin", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(null);
      })
    );
  }

  public updateB2BMargemConsumidor(percentual_margem: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": localStorage.getItem("client_id_app"),
      "percentual_margem": percentual_margem
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/UpdateB2BMargemConsumidor", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updatePasswordById(password_b2b: string): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": localStorage.getItem("client_id_app"),
      "password_b2b": password_b2b
    };

    return this.http.post<boolean>(this.baseUrl + "api/Cliente/UpdatePasswordById", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Clientes (Empresas) */

  public getByDocumento(client_document: string): Observable<ClienteEmpresa[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_document": client_document
    };

    return this.http.post<ClienteEmpresa[]>(this.baseUrl + "api/Cliente/GetByDocumento", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Cliente (Local Entrega) */

  public getClienteLocalEntregaByCliente(id: number): Observable<ClienteLocalEntrega[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id": id
    };

    return this.http.post<ClienteLocalEntrega[]>(this.baseUrl + "api/Cliente/GetClienteLocalEntregaByCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Animacao } from '../../core/tools/animacao';

/* Services */

import { NovoInteresseService } from '../../core/services/novos-interesses/novo-interesse.service';

/* Models */

import { NovoInteresse } from '../../core/models/novos-interesses/novo-interesse';

@Component({
  selector: 'app-tenho-interesse',
  templateUrl: './tenho-interesse.component.html',
  styleUrls: ['./tenho-interesse.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class TenhoInteresseComponent implements OnInit {
  /* Dados */

  novoInteresse: NovoInteresse = null;

  /* Logomarca */

  logomarca: string = "";

  constructor(
    private router: Router,
    private novoInteresseService: NovoInteresseService
  ) { }

  ngOnInit() {
    /* Empresa */

    if (window.location.href.indexOf("atrialub") > -1) {
      this.logomarca = "assets/logo_portal_atrialub.jpeg";
    } else if (window.location.href.indexOf("brudovan") > -1) {
      this.logomarca = "assets/logo_portal_brudovan.png";
    } else if (window.location.href.indexOf("lubtrol") > -1) {
      this.logomarca = "assets/logo_portal_lubtrol.png";
    } else {
      this.logomarca = "assets/logo_portal.jpeg";
    }

    this.carregarDados();
  }

  /* Dados */

  carregarDados() {
    let dominio: string = window.location.host;

    this.novoInteresseService.getByDominio(dominio).subscribe(
      novosInteresses => this.novoInteresse = novosInteresses,
      error => { console.log("Erro: " + error) },
      () => {
        console.log(this.novoInteresse);
      }
    );
  }

  /* Ações */

  cadastrarme() {
    window.open(this.novoInteresse.link_botao, "_blank");
  }

  voltar() {
    this.router.navigate(["/login"]);
  }

  whatsapp() {
    window.open(this.novoInteresse.whatsapp, "_blank");
  }
}

import { MeiosPagamentoCartoesMercadoPagoRetorno } from "../meios-pagamento/cartoes/mercado-pago/meios-pagamento-cartoes-mercado-pago-retorno";

export class Pedido {
  public id: number;
  public client_id: number;
  public order_created_at: Date;
  public is_billed: boolean;
  public visit_id: number;
  public order_sent_on: Date;
  public order_canceled_on: Date;
  public is_active: boolean;
  public updated_at: string;
  public created_at: Date;
  public sale_channel_id: number;
  public is_quotation: boolean;
  public payment_condition_1: number;
  public payment_condition_2: number;
  public payment_condition_3: number;
  public payment_condition_4: number;
  public payment_condition_5: number;
  public payment_condition_6: number;
  public payment_condition_7: number;
  public seller_id: number;
  public exported_at: Date;
  public referencia_do_pedido_no_cliente: string;
  public dt_previsao_entrega: Date;
  public observacao_interna: string;
  public observacao_nf: string;
  public dt_aprovacao: Date;
  public dt_integracao: Date;
  public id_prazo_pagamento: number;
  public order_id_app: number;
  public dt_ultima_atualizacao: Date;
  public cd_transportadora: number;
  public status_aprovacao_gestor: string;
  public status_aprovacao_master: string;
  public dt_liberacao_gestor: Date;
  public dt_liberacao_master: Date;
  public percentual_desconto: number;
  public id_sinc: number;
  public cod_tipopedvenda: string;
  public id_tipo_frete: number;
  public ativo_bi: string;
  public master_seller_id: number;
  public flag_enviar_celular: string;
  public id_no_erp_do_cliente: number;
  public flag_fechou_pedido: string;
  public dt_faturamento_erp: Date;
  public flag_erro_integracao: string;
  public order_id_origem_bonificacao: number;
  public vlr_bonificacao: number;
  public qtd_itens_ativos: number;
  public tabela_utilizada: string;
  public order_ecommerce: number;
  public origem_pedido: string;
  public fullname: string;
  public celular: string;
  public email_real: string;
  public nome_equipe: string;
  public name: string;
  public ie: string;
  public fantasia: string;
  public client_document: string;
  public endereco: string;
  public end_numero: string;
  public bairro: string;
  public cidade: string;
  public cep: string;
  public phone: string;
  public email: string;
  public nm_transportadora: string;
  public prazo_pagamento: string;
  public tipo_venda: string;
  public id_forma_pagamento: number;
  public dt_validade: Date;
  public id_local_entrega: number;
  public status_aprovacao_diretor: string;
  public guidclientenapis: string;
  public mp_pagamento: MeiosPagamentoCartoesMercadoPagoRetorno;

  constructor() { }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Componentes */

import { ConfiguracoesComponent } from './components/configuracoes/configuracoes.component';
import { LoginComponent } from './components/login/login.component';
import { MeusPedidosComponent } from './components/meus-pedidos/meus-pedidos.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ProdutosComponent } from './components/produtos/produtos.component';
import { RedefinirSenhaComponent } from './components/redefinir-senha/redefinir-senha.component';
import { TenhoInteresseComponent } from './components/tenho-interesse/tenho-interesse.component';

/* Auth */

import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [AuthGuard] },

  { path: 'configuracoes', component: ConfiguracoesComponent, canActivate: [AuthGuard] },

  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },

  { path: 'meus-pedidos', component: MeusPedidosComponent, canActivate: [AuthGuard] },

  { path: 'produtos', component: ProdutosComponent, canActivate: [AuthGuard] },

  { path: 'redefinir-senha', component: RedefinirSenhaComponent, canActivate: [AuthGuard] },

  { path: 'tenho-interesse', component: TenhoInteresseComponent },

  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { ProdutoCategoria } from '../../models/produtos/produto-categoria';
import { ProdutoDisponibilidade } from '../../models/produtos/produto-disponibilidade';
import { ProdutoFichaTecnica } from '../../models/produtos/produto-ficha-tecnica';
import { ProdutoFoto } from '../../models/produtos/produto-foto';
import { ProdutoGrupoNome } from '../../models/produtos/produto-grupo-nome';
import { ProdutoPromocional } from '../../models/produtos/produto-promocional';
import { ProdutoSegmento } from '../../models/produtos/produto-segmento';
import { ProdutoTabelaPromocaoQtde } from '../../models/produtos/produto-tabela-promocao-qtde';

@Injectable({
  providedIn: 'root'
})

export class ProdutoService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  /* Produto (Categoria) */

  public getCategoriaByGuidClienteNapis(): Observable<ProdutoCategoria[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<ProdutoCategoria[]>(this.baseUrl + "api/Produto/GetCategoriaByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Disponilidade) */

  public addDisponibilidade(produto: ProdutoDisponibilidade): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produto": produto
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/AddDisponibilidade", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public deleteDisponibilidade(product_id: number): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": localStorage.getItem("client_id_app"),
      "product_id": product_id
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/DeleteDisponibilidade", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getDisponibilidadeByCliente(): Observable<ProdutoDisponibilidade[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": localStorage.getItem("client_id_app"),
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<ProdutoDisponibilidade[]>(this.baseUrl + "api/Pedido/GetDisponibilidadeByCliente", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getDisponibilidadeByProduto(): Observable<ProdutoDisponibilidade[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "product_id": localStorage.getItem("product_id"),
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<ProdutoDisponibilidade[]>(this.baseUrl + "api/Produto/GetDisponibilidadeByProduto", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public updateDisponibilidadeQtde(produto: ProdutoDisponibilidade): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produto": produto
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/UpdateDisponibilidadeQtde", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public updateDisponibilidadeNotificado(produto: ProdutoDisponibilidade): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "produto": produto
    };

    return this.http.post<boolean>(this.baseUrl + "api/Produto/UpdateDisponibilidadeNotificado", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  /* Produto (Ficha Técnica) */

  public getFichaTecnicaByIdProduto(id_produto: number): Observable<ProdutoFichaTecnica[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_produto": id_produto
    };

    return this.http.post<ProdutoFichaTecnica[]>(this.baseUrl + "api/Produto/GetFichaTecnicaByIdProduto", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Foto) */

  public getProdutoFotosByIdProduto(id_produto: number): Observable<ProdutoFoto[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "id_produto": id_produto
    };

    return this.http.post<ProdutoFoto[]>(this.baseUrl + "api/Produto/GetProdutoFotosByIdProduto", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  public getProdutoFotosByIds(ids: number[]): Observable<ProdutoFoto[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "ids": ids
    };

    return this.http.post<ProdutoFoto[]>(this.baseUrl + "api/Produto/GetProdutoFotosByIds", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Grupo Nome) */

  public getProdutoGrupoNomeByGuidClienteNapis(): Observable<ProdutoGrupoNome[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis")
    };

    return this.http.post<ProdutoGrupoNome[]>(this.baseUrl + "api/Produto/GetProdutoGrupoNomeByGuidClienteNapis", data, { headers: this.headers, reportProgress: true }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Promocional) */

  public getProdutoPromocionalByGuidClienteNapis(): Observable<ProdutoPromocional[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "mostrar_b2b": true,
      "client_id_app": localStorage.getItem("client_id_app"),
      "seller_id": localStorage.getItem("seller_id"),
      "nome_lote": "",
      "id_forma_pagamento": null,
      "id_prazo_pagamento": null
    };

    return this.http.post<ProdutoPromocional[]>(this.baseUrl + "api/Produto/GetProdutoPromocionalByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Segmento) */

  public getProdutoSegmentoByGuidClienteNapis(): Observable<ProdutoSegmento[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "segment": localStorage.getItem("segment")
    };

    return this.http.post<ProdutoSegmento[]>(this.baseUrl + "api/Produto/GetProdutoSegmentoByGuidClienteNapis", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Produto (Tabela Promoção Qtde) */

  public getProdutoTabelaPromocaoQtde(id_produto: number, preco: number): Observable<ProdutoTabelaPromocaoQtde[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "client_id_app": localStorage.getItem("client_id_app"),
      "seller_id": localStorage.getItem("seller_id"),
      "id_produto": id_produto,
      "preco": preco
    };

    return this.http.post<ProdutoTabelaPromocaoQtde[]>(this.baseUrl + "api/Produto/GetProdutoTabelaPromocaoQtde", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suporte',
  templateUrl: './suporte.component.html',
  styleUrls: ['./suporte.component.css'],
  preserveWhitespaces: false
})

export class SuporteComponent implements OnInit {
  /* Storage */

  link_whatsapp: string = localStorage.getItem("link_whatsapp");

  constructor() { }

  ngOnInit() {
  }

  abrirLink() {
    window.open(this.link_whatsapp, "_blank");
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';

/* Services */

import { AuthService } from '../../core/services/auth/auth.service';
import { ClienteService } from '../../core/services/clientes/cliente.service';

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class RedefinirSenhaComponent implements OnInit {
  formRecuperarSenha: FormGroup;

  enviado: boolean = false;

  /* Logomarca */

  logomarca: string = "";

  constructor(
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private clienteService: ClienteService
  ) { }

  ngOnInit() {
    this.formRecuperarSenha = this.formBuilder.group({
      nova_senha: ["", Validators.required],
      confirmar_senha: ["", Validators.required]
    });

    /* Empresa */

    if (window.location.href.indexOf("atrialub") > -1) {
      this.logomarca = "assets/logo_portal_atrialub.jpeg";
    } else if (window.location.href.indexOf("brazmax") > -1) {
      this.logomarca = "assets/logo_portal_brazmax.jpeg";
    } else if (window.location.href.indexOf("brudovan") > -1) {
      this.logomarca = "assets/logo_portal_brudovan.png";
    } else if (window.location.href.indexOf("lubtrol") > -1) {
      this.logomarca = "assets/logo_portal_lubtrol.png";
    } else {
      this.logomarca = "assets/logo_portal.jpeg";
    }
  }

  get f() {
    return this.formRecuperarSenha.controls;
  }

  /* Ações */

  onSubmit() {
    this.enviado = true;

    if (this.formRecuperarSenha.valid) {
      if (this.formRecuperarSenha.controls["nova_senha"].value != this.formRecuperarSenha.controls["confirmar_senha"].value) {
        this.formRecuperarSenha.controls["confirmar_senha"].setErrors({ mismatch: true });
      } else {
        let alterarSenha: boolean = false;

        this.clienteService.updatePasswordById(this.formRecuperarSenha.controls["nova_senha"].value).subscribe(
          alterarSenhas => alterarSenha = alterarSenhas,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarSenha) {
              this.router.navigate(["/produtos"]);
            }
          }
        );
      }
    }
  }

  cancelar() {
    this.authService.desautorizar();
  }
}

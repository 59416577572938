import { Component, OnInit } from '@angular/core';
import { Animacao } from '../../core/tools/animacao';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class NotFoundComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}

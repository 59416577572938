import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

/* Services */

import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  /* Storage */

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (route.routeConfig.path == "" || route.routeConfig.path == "login") {
      let guidclientenapis: string = localStorage.getItem("guidclientenapis");

      if (guidclientenapis != null && guidclientenapis != "") {
        this.router.navigate(["/produtos"]);

        return false;
      } else {
        return true;
      }
    } else {
      if (this.authService.statusAutorizacao()) {
        return true;
      } else {
        this.authService.desautorizar();

        return false;
      }
    }
  }
}

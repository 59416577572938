import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-rodape',
  templateUrl: './rodape.component.html',
  styleUrls: ['./rodape.component.css'],
  preserveWhitespaces: false
})

export class RodapeComponent implements OnInit {
  texto_informativo: string = "";

  /* Storage */

  guidclientenapis: string = localStorage.getItem("guidclientenapis");
  nm_empresa: string = localStorage.getItem("nm_empresa");
  empresa_fone: string = localStorage.getItem("empresa_fone");
  empresa_endereco_bairro: string = localStorage.getItem("empresa_endereco_bairro");
  empresa_cidade_estado: string = localStorage.getItem("empresa_cidade_estado");
  empresa_email: string = localStorage.getItem("empresa_email");
  b2b_cor_primaria: string = localStorage.getItem("b2b_cor_primaria");

  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.guidclientenapis == "5c430e97-cea3-43ca-bfae-dcc4f8f558cb") {
      this.texto_informativo = "";
    }
  }
}

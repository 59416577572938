import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ListenerService {
  private requisicoesPendente: BehaviorSubject<boolean>;

  constructor() {
    this.requisicoesPendente = new BehaviorSubject(false);
  }

  atualizarRequisicoesPendentes(pendente: boolean) {
    this.requisicoesPendente.next(pendente);
  }

  verificarRequisicoesPendentes(): Observable<boolean> {
    return this.requisicoesPendente.asObservable();
  }
}

import { Injectable } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import * as jsPDF from 'jspdf';

/* Services */

import { CallCenterService } from '../services/call-center/call-center.service';
import { ConfiguracaoService } from '../services/configuracoes/configuracao.service';
import { PedidoService } from '../services/pedidos/pedido.service';
import { PedidoItensService } from '../services/pedidos/pedido-itens.service';
import { PrazoPagamentoItensService } from '../services/prazos-pagamento/prazo-pagamento-itens.service';

/* Models */

import { CallCenterConfiguracao } from '../models/call-center/call-center-configuracao';
import { Configuracao } from '../models/configuracoes/configuracao';
import { Pedido } from '../models/pedidos/pedido';
import { PedidoItens } from '../models/pedidos/pedido-itens';
import { PrazoPagamentoItens } from '../models/prazos-pagamento/prazo-pagamento-itens';

@Injectable({
  providedIn: 'root'
})

export class PdfService {
  constructor(
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private callCenterService: CallCenterService,
    private configuracaoService: ConfiguracaoService,
    private pedidoService: PedidoService,
    private pedidoItensService: PedidoItensService,
    private prazoPagamentoItensService: PrazoPagamentoItensService
  ) { }

  /* Pedido */

  imprimirPedidoOrcamento(id_pedido: number, seller_id: number, porcentagem_desconto: number) {
    /* Call Center (Configuração) */

    let callCenterConfiguracao: CallCenterConfiguracao = null;

    /* Configurações */

    let configuracao: Configuracao = null;

    /* Pedido */

    let pedido: Pedido = null;

    /* Pedido Itens */

    let pedidoItens: PedidoItens[] = [];

    /* Prazo Pagto (Items) */

    let prazoPagamentoItens: PrazoPagamentoItens[] = [];

    /* Call Center (Configuração) */

    this.callCenterService.getConfiguracaoByGuidClienteNapis().subscribe(
      callCenterConfiguracoes => callCenterConfiguracao = callCenterConfiguracoes,
      error => { console.log("Erro: " + error) },
      () => {
        if (callCenterConfiguracao != null) {
          /* Configurações */

          this.configuracaoService.getByGuidClienteNapis().subscribe(
            configuracoes => configuracao = configuracoes,
            error => { console.log("Erro: " + error) },
            () => {
              if (configuracao != null) {
                /* Pedido */

                this.pedidoService.getByOrder(id_pedido, seller_id).subscribe(
                  pedidos => pedido = pedidos,
                  error => { console.log("Erro: " + error) },
                  () => {
                    if (pedido != null) {
                      /* Pedido Itens */

                      this.pedidoItensService.getByOrder(id_pedido, pedido.seller_id).subscribe(
                        pedidoItem => pedidoItens = pedidoItem,
                        error => { console.log("Erro: " + error) },
                        () => {
                          if (pedidoItens.length > 0) {
                            if (porcentagem_desconto != null && porcentagem_desconto != 0) {
                              pedidoItens.forEach((item) => {
                                item.unit_price = (item.unit_price - ((item.unit_price * porcentagem_desconto) / 100));
                                item.total = item.quantity * item.unit_price;
                              });
                            }

                            /* Prazo Pagto (Item) */

                            this.prazoPagamentoItensService.getByIdPrazoPagamento(pedido.id_prazo_pagamento).subscribe(
                              prazoPagamentoItem => prazoPagamentoItens = prazoPagamentoItem,
                              error => { console.log("Erro: " + error) },
                              () => {
                                if (prazoPagamentoItens.length > 0) {
                                  this.gerarPedidoOrcamento(callCenterConfiguracao, configuracao, pedido, pedidoItens, prazoPagamentoItens);

                                  this.toastr.success("", "Arquivo gerado com sucesso!", { positionClass: "toast-bottom-left" });
                                }
                              }
                            );
                          }
                        }
                      );
                    }
                  }
                );
              }
            }
          );
        }
      }
    );
  }

  gerarPedidoOrcamento(callCenterConfiguracao: CallCenterConfiguracao, configuracao: Configuracao, pedido: Pedido, pedidoItens: PedidoItens[], prazoPagamentoItens: PrazoPagamentoItens[]) {
    let pdf = new jsPDF();

    if (configuracao.id == 8) {
      // UniBrazil

      pdf.addImage(callCenterConfiguracao.logotipo, 7, 2, 80, 35);
    } else if (configuracao.id == 23) {
      // Oimasa

      pdf.addImage(callCenterConfiguracao.logotipo, 9, 5, 80, 30);
    } else if (configuracao.id == 45) {
      // MOTOSUPRI Distribuidora

      pdf.addImage(callCenterConfiguracao.logotipo, 10, 5, 75, 25);
    } else if (configuracao.id == 49) {
      // Indústrias NAT LTDA

      pdf.addImage(callCenterConfiguracao.logotipo, 9, 5, 28, 28);
    } else if (configuracao.id == 66) {
      // Petrovila Química Ltda

      pdf.addImage(callCenterConfiguracao.logotipo, 10, 5, 80, 30);
    } else {
      // Brudovan / Outras

      pdf.addImage(callCenterConfiguracao.logotipo, 5, -5, 90, 40);
    }

    pdf.setFontSize(14);
    pdf.setFontType("bold");

    pdf.text(configuracao.nm_empresa == null ? "" : configuracao.nm_empresa, 200, 10, { align: "right" });

    pdf.setFontSize(12);
    pdf.setFontType("normal");

    pdf.text(configuracao.empresa_endereco == null ? "" : configuracao.empresa_endereco.trim(), 200, 15, { align: "right" });
    pdf.text(configuracao.empresa_bairro == null ? "" : configuracao.empresa_bairro.trim(), 200, 20, { align: "right" });
    pdf.text(configuracao.empresa_cidade_estado == null ? "" : configuracao.empresa_cidade_estado.trim(), 200, 25, { align: "right" });
    pdf.text(configuracao.empresa_email == null ? "" : configuracao.empresa_email.trim(), 200, 30, { align: "right" });
    pdf.text(configuracao.empresa_fone == null ? "" : "TELEFONE: " + configuracao.empresa_fone.trim(), 200, 35, { align: "right" });

    pdf.line(10, 37, 200, 37, "FD");

    pdf.setFontSize(14);
    pdf.setFontType("bold");

    if (pedido.order_sent_on != null) {
      pdf.text("PEDIDO " + pedido.order_id_app + " - " + pedido.tipo_venda, 10, 42);
    } else {
      pdf.text("ORÇAMENTO " + pedido.order_id_app, 10, 42);
    }

    /* Vendedor(a) */

    /* Cabeçalho */

    pdf.setFontSize(10);
    pdf.setFontType("bold");

    pdf.line(10, 44, 200, 44, "FD");

    pdf.text("Vendedor(a):", 10, 49);
    pdf.text("Data:", 140, 49);
    pdf.text("E-mail:", 10, 54);

    if (pedido.dt_validade != null) {
      pdf.text("Data validade:", 140, 54);
    }

    pdf.text("Telefone:", 10, 59);

    if (pedido.dt_previsao_entrega != null) {
      pdf.text("Data entrega:", 140, 59);
    }

    /* Dados */

    pdf.setFontType("normal");

    if (configuracao.seller_tmk_pdf) {
      pdf.text(pedido.nome_equipe == null ? "" : pedido.nome_equipe, 32, 49);
    } else {
      pdf.text(pedido.fullname == null ? "" : pedido.fullname, 32, 49);
    }

    if (pedido.order_sent_on != null) {
      pdf.text(this.datePipe.transform(pedido.order_sent_on, "dd/MM/yyyy HH:mm:ss"), 165, 49);
    } else {
      pdf.text(this.datePipe.transform(pedido.order_created_at, "dd/MM/yyyy HH:mm:ss"), 165, 49);
    }

    pdf.text(pedido.email_real == null ? "" : pedido.email_real, 32, 54);

    if (pedido.dt_validade != null) {
      pdf.text(this.datePipe.transform(pedido.dt_validade, "dd/MM/yyyy"), 165, 54);
    }

    pdf.text(pedido.celular == null ? "" : pedido.celular, 32, 59);

    if (pedido.dt_previsao_entrega != null) {
      pdf.text(this.datePipe.transform(pedido.dt_previsao_entrega, "dd/MM/yyyy"), 165, 59);
    }

    /* Cabeçalho */

    pdf.setFontSize(10);
    pdf.setFontType("bold");

    pdf.line(10, 61, 200, 61, "FD");

    pdf.text("Cliente:", 10, 66);
    pdf.text("Endereço:", 10, 71);
    pdf.text("CNPJ/CPF:", 120, 71);
    pdf.text("Bairro:", 10, 76);
    pdf.text("IE:", 120, 76);
    pdf.text("Telefone(s):", 10, 81);
    pdf.text("Cidade:", 120, 81);
    pdf.text("E-mail:", 10, 86);
    pdf.text("CEP: ", 120, 86);

    /* Dados */

    pdf.setFontType("normal");

    pdf.text(pedido.name == null ? "" : pedido.name, 32, 66);

    if (pedido.endereco != null && pedido.end_numero != null) {
      pdf.text(pedido.endereco + ", " + pedido.end_numero, 32, 71);
    } else if (pedido.endereco != null && pedido.end_numero == null) {
      pdf.text(pedido.endereco, 32, 71);
    } else {
      pdf.text("", 32, 71);
    }

    pdf.text(pedido.client_document == null ? "" : pedido.client_document, 142, 71);
    pdf.text(pedido.bairro == null ? "" : pedido.bairro, 32, 76);
    pdf.text(pedido.ie == null ? "" : pedido.ie, 142, 76);
    pdf.text(pedido.phone == null ? "" : pedido.phone, 32, 81);
    pdf.text(pedido.cidade == null ? "" : pedido.cidade, 142, 81);
    pdf.text(pedido.email == null ? "" : pedido.email, 32, 86);
    pdf.text(pedido.cep == null ? "" : pedido.cep, 142, 86);

    /* Transportadora */

    /* Cabeçalho */

    pdf.setFontSize(10);
    pdf.setFontType("bold");

    pdf.line(10, 88, 200, 88, "FD");

    pdf.text("Transportadora:", 10, 93);

    /* Dados */

    pdf.setFontType("normal");

    pdf.text(pedido.nm_transportadora == null ? "" : pedido.nm_transportadora, 42, 93);

    /* Produtos */

    pdf.setFontType("bold");

    pdf.line(10, 95, 200, 95, "FD");

    let produtos: string = "PRODUTOS";
    let posicaoProdutos: number = ((pdf.internal.pageSize.width - ((pdf.getStringUnitWidth(produtos) * pdf.internal.getFontSize()) / pdf.internal.scaleFactor)) / 2);

    pdf.text(produtos, posicaoProdutos, 100);

    pdf.setFontSize(8);

    pdf.line(10, 102, 200, 102, "FD");

    if (configuracao.flag_exibir_imposto_produto) {
      pdf.text("#", 13, 106, { align: "center" });
      pdf.text("Código", 23, 106, { align: "center" });
      pdf.text("Descrição", 31, 106);
      pdf.text("Grupo Estoque", 100, 106, { align: "center" });
      pdf.text("Qtde", 126, 106, { align: "center" });
      pdf.text("Unitário: R$", 152, 106, { align: "right" });
      pdf.text("Imp. + F.F.: R$", 178, 106, { align: "right" });
      pdf.text("Sub-Total: R$", 200, 106, { align: "right" });
    } else {
      pdf.text("#", 13, 106, { align: "center" });
      pdf.text("Código", 23, 106, { align: "center" });
      pdf.text("Descrição", 31, 106);
      pdf.text("Grupo Estoque", 135, 106, { align: "center" });
      pdf.text("Qtde", 152, 106, { align: "center" });
      pdf.text("Unitário: R$", 178, 106, { align: "right" });
      pdf.text("Total: R$", 200, 106, { align: "right" });
    }

    pdf.setFontType("normal");

    pdf.line(10, 108, 200, 108, "FD");

    let item: number = 0;
    let itemDescricaoPartes: string[] = [];
    let linha: number = 112;
    let tamanho: number = 55;

    if (configuracao.flag_exibir_imposto_produto) {
      tamanho = 35;
    }

    let valorTotalProduto: number = 0;
    let valorTotalImposto: number = 0;
    let valorTotalFatorFinanceiro: number = 0;
    let valorTotal: number = 0;

    for (var i = 0; i < pedidoItens.length; i++) {
      item++;
      itemDescricaoPartes = this.dividirTexto(pedidoItens[i].produto_nome, tamanho);

      valorTotalProduto = (valorTotalProduto + ((pedidoItens[i].unit_price - pedidoItens[i].fator_financeiro) * pedidoItens[i].quantity));
      valorTotalImposto = (valorTotalImposto + (pedidoItens[i].valor_imposto * pedidoItens[i].quantity));
      valorTotalFatorFinanceiro = (valorTotalFatorFinanceiro + (pedidoItens[i].fator_financeiro * pedidoItens[i].quantity));

      if (configuracao.flag_exibir_imposto_produto) {
        valorTotal = (valorTotal + (pedidoItens[i].total + (pedidoItens[i].valor_imposto * pedidoItens[i].quantity)));
      } else {
        valorTotal = valorTotal + pedidoItens[i].total;
      }

      for (var j = 0; j < itemDescricaoPartes.length; j++) {
        if (j == 0) {
          if (configuracao.flag_exibir_imposto_produto) {
            pdf.text(item.toString(), 13, linha, { align: "center" });
            pdf.text(pedidoItens[i].produto_codigo == null ? "" : pedidoItens[i].produto_codigo.toString(), 23, linha, { align: "center" });
            pdf.text(itemDescricaoPartes[j], 31, linha);

            pdf.text(pedidoItens[i].grupo_estoque == null ? "" : pedidoItens[i].grupo_estoque.substring(0, 15), 100, linha, { align: "center" });
            pdf.text(pedidoItens[i].quantity.toString(), 126, linha, { align: "center" });

            /* Unitário: R$ */

            let valorUnitarioOriginal: number = pedidoItens[i].unit_price - pedidoItens[i].fator_financeiro;

            pdf.text(this.currencyPipe.transform(valorUnitarioOriginal.toString(), "", "R$"), 152, linha, { align: "right" });

            /* Imp. + F.F.: R$ */

            let valorImpostoComFatorFinanceiro: number = ((pedidoItens[i].valor_imposto + pedidoItens[i].fator_financeiro) * pedidoItens[i].quantity);

            pdf.text(this.currencyPipe.transform(valorImpostoComFatorFinanceiro, "", "R$", "1.4-4"), 178, linha, { align: "right" });

            /* Sub-Total: R$ */

            let valorImposto: number = pedidoItens[i].valor_imposto * pedidoItens[i].quantity;
            let subTotal: number = pedidoItens[i].total + valorImposto;

            pdf.text(this.currencyPipe.transform(subTotal.toString(), "", "R$"), 200, linha, { align: "right" });
          } else {
            pdf.text(item.toString(), 13, linha, { align: "center" });
            pdf.text(pedidoItens[i].produto_codigo == null ? "" : pedidoItens[i].produto_codigo.toString(), 23, linha, { align: "center" });
            pdf.text(itemDescricaoPartes[j], 31, linha);
            pdf.text(pedidoItens[i].grupo_estoque == null ? "" : pedidoItens[i].grupo_estoque.substring(0, 15), 135, linha, { align: "center" });
            pdf.text(pedidoItens[i].quantity.toString(), 152, linha, { align: "center" });
            pdf.text(this.currencyPipe.transform(pedidoItens[i].unit_price.toString(), "", "R$"), 178, linha, { align: "right" });
            pdf.text(this.currencyPipe.transform(pedidoItens[i].total.toString(), "", "R$"), 200, linha, { align: "right" });
          }
        } else {
          pdf.text(itemDescricaoPartes[j], 31, linha);
        }

        linha = linha + 5;

        if (linha > 286) {
          pdf.line(10, 286, 200, 286, "FD");

          pdf.addPage();

          linha = 10;

          pdf.line(10, linha, 200, linha, "FD");

          linha = linha + 4;
        }
      }
    }

    /* Total */

    pdf.setFontSize(10);
    pdf.setFontType("bold");

    let colunaTotalDescricao: number = 130;
    let colunaTotal: number = 200;

    /* Produto */

    let totalProdutoDescricao: string = "PRODUTOS:";
    let totalProduto = this.currencyPipe.transform(valorTotalProduto.toString(), "", "R$");

    /* Imposto */

    let totalImpostoDescricao: string = "IMPOSTOS:";
    let totalImposto = this.currencyPipe.transform(valorTotalImposto.toString(), "", "R$");

    /* Fator Financeiro */

    let totalFatorFinanceiroDescricao: string = "FATOR FINANCEIRO:";
    let totalFatorFinanceiro = this.currencyPipe.transform(valorTotalFatorFinanceiro.toString(), "", "R$");

    /* Total */

    let totalDescricao: string = "TOTAL:";
    let total = this.currencyPipe.transform(valorTotal.toString(), "", "R$");

    linha = linha - 3;

    pdf.line(10, linha, 200, linha, "FD");

    if (configuracao.flag_exibir_imposto_produto) {
      pdf.setFontType("normal");

      /* Produto */

      linha = linha + 4;

      if (linha > 286) {
        pdf.addPage();

        linha = 10;

        pdf.line(10, linha, 200, linha, "FD");

        linha = linha + 4;
      }

      pdf.text(totalProdutoDescricao, colunaTotalDescricao, linha);
      pdf.text(totalProduto, colunaTotal, linha, { align: "right" });

      /* Imposto */

      linha = linha + 4;

      if (linha > 286) {
        pdf.addPage();

        linha = 10;

        pdf.line(10, linha, 200, linha, "FD");

        linha = linha + 4;
      }

      pdf.text(totalImpostoDescricao, colunaTotalDescricao, linha);
      pdf.text(totalImposto, colunaTotal, linha, { align: "right" });

      /* Fator Financeiro */

      linha = linha + 4;

      if (linha > 286) {
        pdf.addPage();

        linha = 10;

        pdf.line(10, linha, 200, linha, "FD");

        linha = linha + 4;
      }

      pdf.text(totalFatorFinanceiroDescricao, colunaTotalDescricao, linha);
      pdf.text(totalFatorFinanceiro, colunaTotal, linha, { align: "right" });

      pdf.setFontType("bold");
    }

    /* Total */

    linha = linha + 4;

    if (linha > 286) {
      pdf.addPage();

      linha = 10;

      pdf.line(10, linha, 200, linha, "FD");

      linha = linha + 4;
    }

    pdf.text(totalDescricao, colunaTotalDescricao, linha);
    pdf.text(total, colunaTotal, linha, { align: "right" });

    /* Simulação / Observações */

    linha = linha + 20;

    if (linha > 226) {
      pdf.addPage();

      linha = 10;

      pdf.line(10, linha, 200, linha, "FD");

      linha = linha + 4;
    }

    let linhaInicial = linha;

    pdf.line(10, linha, 200, linha, "FD");

    pdf.setFontSize(10);
    pdf.setFontType("bold");

    linha = linha + 5;

    if (linha > 286) {
      pdf.addPage();

      linha = 10;
      linhaInicial = linha;

      pdf.line(10, linha, 200, linha, "FD");

      linha = linha + 4;
    }

    pdf.text("Simulação da Condição de Pagamento:", 12, linha);
    pdf.text("Observações:", 102, linha);

    pdf.setFontType("normal");

    linha = linha + 5;

    if (linha > 286) {
      pdf.addPage();

      linha = 10;
      linhaInicial = linha;

      pdf.line(10, linha, 200, linha, "FD");

      linha = linha + 4;
    }

    pdf.text(pedido.prazo_pagamento == null ? "" : pedido.prazo_pagamento, 12, linha);

    let observacao: string = "";

    if (pedido.observacao_nf != null) {
      observacao = pdf.splitTextToSize(pedido.observacao_nf, 96);
    }

    pdf.text(observacao, 102, linha);

    linha = linha + 2;

    if (linha > 286) {
      pdf.addPage();

      linha = 10;
      linhaInicial = linha;

      pdf.line(10, linha, 200, linha, "FD");

      linha = linha + 4;
    }

    pdf.line(12, linha, 98, linha, "FD");

    linha = linha + 4;

    if (linha > 286) {
      pdf.addPage();

      linha = 10;
      linhaInicial = linha;

      pdf.line(10, linha, 200, linha, "FD");

      linha = linha + 4;
    }

    pdf.setFontSize(8);
    pdf.setFontType("bold");

    pdf.text("Parcela", 17, linha, { align: "center" });
    pdf.text("Vencimento", 52, linha, { align: "center" });
    pdf.text("Valor: R$", 98, linha, { align: "right" });

    pdf.setFontType("normal");

    linha = linha + 2;

    pdf.line(12, linha, 98, linha, "FD");

    linha = linha + 4;

    if (linha > 286) {
      pdf.addPage();

      linha = 10;
      linhaInicial = linha;

      pdf.line(10, linha, 200, linha, "FD");

      linha = linha + 4;
    }

    let subItem: number = 0;
    let valorParcela: number = (valorTotal / prazoPagamentoItens.length);
    let dataVencimento: Date = null;

    for (var i = 0; i < prazoPagamentoItens.length; i++) {
      subItem++;

      if (pedido.order_sent_on == null) {
        dataVencimento = new Date(pedido.order_created_at);
      } else {
        dataVencimento = new Date(pedido.order_sent_on);
      }

      dataVencimento.setDate(dataVencimento.getDate() + prazoPagamentoItens[i].diasvencimento);

      pdf.text(subItem.toString(), 17, linha, { align: "center" });
      pdf.text(this.datePipe.transform(dataVencimento, "dd/MM/yyyy"), 52, linha, { align: "center" });
      pdf.text(this.currencyPipe.transform(valorParcela.toString(), "", "R$"), 98, linha, { align: "right" });

      linha = linha + 5;

      if (linha > 286) {
        pdf.addPage();

        linha = 10;
        linhaInicial = linha;

        pdf.line(10, linha, 200, linha, "FD");

        linha = linha + 4;
      }
    }

    linha = linha - 3;

    pdf.line(10, linhaInicial, 10, linha, "FD");
    pdf.line(100, linhaInicial, 100, linha, "FD");
    pdf.line(200, linhaInicial, 200, linha, "FD");

    pdf.line(10, linha, 200, linha, "FD");

    pdf.addImage(callCenterConfiguracao.arte_rodape, 5, 265, 200, 20);

    pdf.save(pedido.order_id_app + ".pdf");
  }

  /* Auxiliares */

  dividirTexto(texto: string, tamanho: number): string[] {
    let partes: string[] = [];

    for (var i = 0; i < texto.length; i += tamanho) {
      partes.push(texto.substring(i, i + tamanho));
    }

    return partes;
  }
}

import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Animacao } from '../../core/tools/animacao';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

/* Services */

import { ConfiguracaoService } from '../../core/services/configuracoes/configuracao.service';
import { NotaFiscalService } from '../../core/services/notas-fiscais/nota-fiscal.service';
import { PdfService } from '../../core/tools/pdf.service';
import { PedidoItensService } from '../../core/services/pedidos/pedido-itens.service';
import { PedidoService } from '../../core/services/pedidos/pedido.service';

/* Models */

import { Configuracao } from '../../core/models/configuracoes/configuracao';
import { NotaFiscal } from '../../core/models/notas-fiscais/nota-fiscal';
import { NotaFiscalFatura } from '../../core/models/notas-fiscais/nota-fiscal-fatura';
import { PedidoItensSintetico } from '../../core/models/pedidos/pedido-itens-sintetico';
import { PedidoPagamento } from '../../core/models/pedidos/pedido-pagamento';
import { PedidoSintetico } from '../../core/models/pedidos/pedido-sintetico';

/* Diretivas */

import { SortableHeaderDirective, SortEvent } from '../../diretivas/sortable-header.directive';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-meus-pedidos',
  templateUrl: './meus-pedidos.component.html',
  styleUrls: ['./meus-pedidos.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class MeusPedidosComponent implements OnInit {
  /* Carregamento */

  carregando: boolean = false;

  /* Configurações */

  configuracao: Configuracao = null;

  /* Notas Fiscais */

  notaFiscal: NotaFiscal = null;

  /* Pedidos */

  pedidos: PedidoSintetico[] = [];
  pedidosOriginal: PedidoSintetico[] = [];

  /* Pedidos Itens */

  pedidosItens: PedidoItensSintetico[] = [];
  pedidosItensOriginal: PedidoItensSintetico[] = [];

  pedidosItensExpandido: number = -1;

  /* Pedidos (Ordenação) */

  onSortPedido({ column, direction }: SortEvent) {
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    if (direction == "") {
      this.pedidos = JSON.parse(JSON.stringify(this.pedidosOriginal));
    } else {
      this.pedidos = this.pedidos.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        const res = compare(a[column], b[column]);

        return direction == "asc" ? res : -res;
      });
    }

    this.pedidosItens = [];
    this.pedidosItensExpandido = -1;
  }

  onSortPedidoItens({ column, direction }: SortEvent) {
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    if (direction == "") {
      this.pedidosItens = JSON.parse(JSON.stringify(this.pedidosItensOriginal));
    } else {
      this.pedidosItens = this.pedidosItens.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        const res = compare(a[column], b[column]);

        return direction == "asc" ? res : -res;
      });
    }
  }

  /* Pedidos (Pagamentos) */

  pedidoPagamento: PedidoPagamento = null;

  /* Palavras */

  palavrasPedidoOrcamento: string = "Pedido";
  palavrasPedidosOrcamentos: string = "Pedidos";

  /* Storage */

  client_id_app: string = localStorage.getItem("client_id_app");
  b2b_usa_como_orcamento: boolean = (localStorage.getItem("b2b_usa_como_orcamento") == "true");

  /* Classificar Colunas */

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private configuracaoService: ConfiguracaoService,
    private notaFiscalService: NotaFiscalService,
    private pdfService: PdfService,
    private pedidoService: PedidoService,
    private pedidoItensService: PedidoItensService
  ) { }

  ngOnInit() {
    if (this.b2b_usa_como_orcamento) {
      this.palavrasPedidoOrcamento = "Orçamento";
      this.palavrasPedidosOrcamentos = "Orçamentos";
    }

    this.carregarDados();
  }

  /* Dados */

  carregarDados() {
    this.carregando = true;

    /* Configurações */

    this.configuracaoService.getByGuidClienteNapis().subscribe(
      configuracoes => this.configuracao = configuracoes,
      error => { console.log("Erro: " + error) },
      () => {
        /* Pedidos */

        this.pedidoService.getB2BByCliente(parseInt(this.client_id_app)).subscribe(
          pedidos => this.pedidos = pedidos,
          error => { console.log("Erro: " + error) },
          () => {
            this.pedidosOriginal = JSON.parse(JSON.stringify(this.pedidos));

            this.carregando = false;
          }
        );
      }
    );
  }

  /* Download */

  downloadPDF(fatura?: NotaFiscalFatura) {
    let base64: string = "";
    let nomeArquivo: string = "";

    if (fatura != null) {
      if (fatura.faturapdf != null) {
        base64 = fatura.faturapdf;
      } else if (fatura.pdffatura != null) {
        base64 = fatura.pdffatura;
      } else {
        return;
      }

      nomeArquivo = fatura.fatura + ".pdf";
    } else {
      base64 = this.notaFiscal.nfpdf;
      nomeArquivo = this.notaFiscal.nf + ".pdf";
    }


    let byteCharacters = atob(base64);
    let byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);
    let blob = new Blob([byteArray], { type: "application/pdf" });

    let url = URL.createObjectURL(blob);

    let link = document.createElement("a");

    link.href = url;

    link.download = nomeArquivo;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  }

  /* Formatação */

  formatarValoresTresCasasDecimais(valor: number) {
    if (valor != null) {
      return (valor).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 3
      });
    }
  }

  /* Pedidos Itens */

  expandirPedidosItens(i: number, id: number, seller_id: number) {
    this.pedidosItensExpandido = i == this.pedidosItensExpandido ? -1 : i;

    if (this.pedidosItensExpandido > -1) {
      this.pedidoItensService.getB2BByOrder(id, seller_id).subscribe(
        pedidosItens => this.pedidosItens = pedidosItens,
        error => { console.log("Erro: " + error) },
        () => {
          this.pedidosItensOriginal = JSON.parse(JSON.stringify(this.pedidosItens));
        }
      );
    }
  }

  /* Imprimir */

  imprimirPedidoOrcamento(id_pedido: number, seller_id: number) {
    this.pdfService.imprimirPedidoOrcamento(id_pedido, seller_id, 0);
  }

  /* Modal */

  modalConsultarNotaFiscal(content: any, nota_fiscal: number, serie: string) {
    /* Notas Fiscais */

    if (serie == null || serie == "") {
      serie = "1";
    }

    this.notaFiscalService.getIntegracaoNotaFiscalByNota(nota_fiscal, serie).subscribe(
      notasFiscais => this.notaFiscal = notasFiscais,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.notaFiscal != null) {
          this.modalService.open(content, {
            ariaLabelledBy: "titulo-consultar-nota-fiscal",
            size: "lg",
            backdrop: "static"
          });
        } else {
          this.toastr.error("", "Nenhuma nota fiscal localizada!");
        }
      }
    );
  }

  modalConsultarPagamento(content: any, id: number) {
    /* Pedidos (Pagamentos) */

    this.pedidoService.getPedidoPagamentoById(id).subscribe(
      pedidoPagamento => this.pedidoPagamento = pedidoPagamento,
      error => { console.log("Erro: " + error) },
      () => {
        this.modalService.open(content, {
          ariaLabelledBy: "titulo-consultar-pagamento",
          size: "lg",
          backdrop: "static"
        });
      }
    );
  }
}

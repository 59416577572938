import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Animacao } from '../../core/tools/animacao';
import { ToastrService } from 'ngx-toastr';

/* Services */

import { AuthService } from '../../core/services/auth/auth.service';
import { ClienteService } from '../../core/services/clientes/cliente.service';
import { NovoInteresseService } from '../../core/services/novos-interesses/novo-interesse.service';

/* Models */

import { ClienteEmpresa } from '../../core/models/clientes/cliente-empresa';
import { NovoInteresse } from '../../core/models/novos-interesses/novo-interesse';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class LoginComponent implements OnInit {
  formLogin: FormGroup;

  enviado: boolean = false;

  /* Catálogo */

  enviadoCatalogo: boolean = false;
  exibirCatalogo: boolean = false;

  /* Dados */

  clientesEmpresas: ClienteEmpresa[] = [];

  clienteEmpresaSelecionado: ClienteEmpresa = null;

  novoInteresse: NovoInteresse = null;

  /* Guid */

  guid_1: string = "";
  guid_2: string = "";
  guid_3: string = "";

  /* Logomarca */

  logomarca: string = "";
  logomarcaDesenvolvimento: string = "";

  /* Texto */

  exibirTexto: boolean = true;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private clienteService: ClienteService,
    private novoInteresseService: NovoInteresseService
  ) { }

  ngOnInit() {
    this.formLogin = this.formBuilder.group({
      cnpj_cpf: ["", Validators.required],
      senha: ["", Validators.required]
    });

    /* Empresa */
    
    if (window.location.href.indexOf("atrialub") > -1) {
      this.logomarca = "assets/logo_portal_atrialub.jpeg";
    } else if (window.location.href.indexOf("brazmax") > -1) {
      this.logomarca = "assets/logo_portal_brazmax.jpeg";
    } else if (window.location.href.indexOf("brudovan") > -1) {
      this.logomarca = "assets/logo_portal_brudovan.png";
    } else if (window.location.href.indexOf("lojasrpm") > -1) {
      /* Texto */

      this.exibirTexto = false;

      this.logomarca = "assets/logo_portal_lojasrpm.png";
    } else if (window.location.href.indexOf("lubtrol") > -1) {
      this.logomarca = "assets/logo_portal_lubtrol.png";
    } else if (window.location.href.indexOf("petrovila") > -1) {
      this.logomarca = "assets/logo_portal_petrovila.jpg";
    } else if (window.location.href.indexOf("produtosatacado") > -1 || window.location.href.indexOf("localhost") > -1) {
      /* Catálogo */

      this.exibirCatalogo = true;

      /* Texto */

      this.exibirTexto = false;

      this.logomarca = "assets/logo_portal_produtos_atacado.png";
    } else if (window.location.href.indexOf("sklubrificantes") > -1) {
      /* Texto */

      this.exibirTexto = false;

      this.logomarca = "assets/logo_portal_sklubrificantes.png";
    } else {
      this.logomarca = "assets/logo_portal.jpeg";
    }

    /* Desenvolvimento */

    if (
      window.location.href.indexOf("lojasrpm") > -1 ||
      window.location.href.indexOf("sklubrificantes") > -1
    ) {
      this.logomarcaDesenvolvimento = "assets/logo_2bdigital_branco.png";
    } else {
      this.logomarcaDesenvolvimento = "assets/logo_napis_preto.png";
    }

    this.carregarDados();
  }

  get f() {
    return this.formLogin.controls;
  }

  /* Dados */

  carregarDados() {
    let dominio: string = window.location.host;

    this.novoInteresseService.getByDominio(dominio).subscribe(
      novosInteresses => this.novoInteresse = novosInteresses,
      error => { console.log("Erro: " + error) },
      () => {

      }
    );
  }

  /* Ações */

  catalogo() {
    let cnpj_cpf: string = "321.801.338-04";

    this.clienteService.getByDocumento(cnpj_cpf).subscribe(
      clientesEmpresas => this.clientesEmpresas = clientesEmpresas,
      error => { console.log("Erro: " + error) },
      () => {
        this.enviadoCatalogo = true;

        setTimeout(() => {
          if (this.clientesEmpresas.length > 0) {
            let clienteEmpresa = this.clientesEmpresas.find(clienteEmpresa => clienteEmpresa.guidclientenapis.toUpperCase() == "21B453CD-D6D1-4A1D-859D-5A46BA7A7219");

            if (clienteEmpresa != null) {
              this.clienteEmpresaSelecionado = clienteEmpresa;

              this.formLogin.controls["cnpj_cpf"].setValue(cnpj_cpf);
              this.formLogin.controls["senha"].setValue("123");

              this.formLogin.updateValueAndValidity();

              this.authService.catalogoEmitter.emit(true);

              this.onSubmit();
            } else {
              this.toastr.error("", "Houve um erro, contate o suporte...");
            }
          } else {
            this.toastr.error("", "Acesso inválido!");
          }
        }, 300);
      }
    );
  }

  onSubmit() {
    this.enviado = true;

    if (this.formLogin.controls["cnpj_cpf"].valid && this.clientesEmpresas.length == 0) {
      let cnpj_cpf: string = this.formLogin.controls["cnpj_cpf"].value.split(".").join("").split("-").join("").split("/").join("");

      if (cnpj_cpf.length == 11) {
        /* CPF */

        cnpj_cpf = cnpj_cpf.substr(0, 3) + "." + cnpj_cpf.substr(3, 3) + "." + cnpj_cpf.substr(6, 3) + "-" + cnpj_cpf.substr(9, 2);
      } else if (cnpj_cpf.length == 14) {
        /* CNPJ */

        cnpj_cpf = cnpj_cpf.substr(0, 2) + "." + cnpj_cpf.substr(2, 3) + "." + cnpj_cpf.substr(5, 3) + "/" + cnpj_cpf.substr(8, 4) + "-" + cnpj_cpf.substr(12, 2);
      } else {
        this.toastr.error("", "CNPJ/CPF inválido!");

        return;
      }

      this.formLogin.controls["cnpj_cpf"].setValue(cnpj_cpf);

      this.clienteService.getByDocumento(cnpj_cpf).subscribe(
        clientesEmpresas => this.clientesEmpresas = clientesEmpresas,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.clientesEmpresas.length > 0) {
            this.clientesEmpresas.forEach((item) => {
              item.exibir = true;
            });

            if (window.location.href.indexOf("atrialub") > -1) {
              /* Atria */

              this.guid_1 = "09F06B45-797E-455D-9B0A-E1531B95A4A7";
              this.guid_2 = "37441631-A638-4469-AD56-6404B0B0BF44";

              this.clientesEmpresas.forEach((item) => {
                if (item.guidclientenapis.toUpperCase() != this.guid_1 && item.guidclientenapis.toUpperCase() != this.guid_2) {
                  item.exibir = false;
                }
              });
            } else if (window.location.href.indexOf("brudovan") > -1) {
              /* Brudovan */

              this.guid_1 = "960D72D6-F28E-414B-AE20-14F033522BE1";
              this.guid_2 = "6B5619F0-DCB2-4CA6-BEEB-67C0AD4AD028";
              this.guid_3 = "14B5BA1F-018A-4A8D-B257-762E889E2B3A";

              this.clientesEmpresas.forEach((item) => {
                if (item.guidclientenapis.toUpperCase() != this.guid_1 && item.guidclientenapis.toUpperCase() != this.guid_2 && item.guidclientenapis.toUpperCase() != this.guid_3) {
                  item.exibir = false;
                }
              });
            } else if (window.location.href.indexOf("lojasrpm") > -1) {
              /* Lojas RPM */

              this.guid_1 = "09C95266-72E6-4503-8DCA-B7B3E95D0421";

              this.clientesEmpresas.forEach((item) => {
                if (item.guidclientenapis.toUpperCase() != this.guid_1) {
                  item.exibir = false;
                }
              });
            } else if (window.location.href.indexOf("petrovila") > -1) {
              /* Petrovila */

              this.guid_1 = "C767BADA-BF09-4A11-900B-ACD76FF0177C";

              this.clientesEmpresas.forEach((item) => {
                if (item.guidclientenapis.toUpperCase() != this.guid_1) {
                  item.exibir = false;
                }
              });
            } else if (window.location.href.indexOf("produtosatacado") > -1) {
              /* Produtos Atacado */

              this.guid_1 = "21B453CD-D6D1-4A1D-859D-5A46BA7A7219";

              this.clientesEmpresas.forEach((item) => {
                if (item.guidclientenapis.toUpperCase() != this.guid_1) {
                  item.exibir = false;
                }
              });
            } else if (window.location.href.indexOf("sklubrificantes") > -1) {
              /* SK Lubrificantes */

              this.guid_1 = "7B6AA2A4-D4BE-40C7-AF60-DB674CD1D11C";

              this.clientesEmpresas.forEach((item) => {
                if (item.guidclientenapis.toUpperCase() != this.guid_1) {
                  item.exibir = false;
                }
              });
            } else if (window.location.href.indexOf("triunfo") > -1) {
              /* Triunfo */

              this.guid_1 = "8B424A42-CE01-49BE-A230-18FF66946661";
              this.guid_2 = "9EC52291-76EC-410D-B0DB-5887E5EE83D1";

              this.clientesEmpresas.forEach((item) => {
                if (item.guidclientenapis.toUpperCase() != this.guid_1 && item.guidclientenapis.toUpperCase() != this.guid_2) {
                  item.exibir = false;
                }
              });
            }

            this.clientesEmpresas = this.clientesEmpresas.filter(clienteEmpresa => clienteEmpresa.exibir);

            if (this.clientesEmpresas.length > 0) {
              this.clienteEmpresaSelecionado = this.clientesEmpresas[0];

              this.formLogin.controls["cnpj_cpf"].disable();
            } else {
              this.toastr.error("", "CNPJ/CPF inválido!");
            }
          } else {
            this.toastr.error("", "CNPJ/CPF inválido!");
          }
        }
      );

      this.enviado = false;
    } else {
      if (this.formLogin.valid && this.clienteEmpresaSelecionado != null) {
        if (this.enviadoCatalogo)
        localStorage.setItem("catalogo", "true");
        this.authService.autorizar(this.formLogin.controls["cnpj_cpf"].value, this.clienteEmpresaSelecionado.guidclientenapis, this.formLogin.controls["senha"].value, this.clienteEmpresaSelecionado);
      }
    }
  }

  retornar() {
    this.enviado = false;

    this.clientesEmpresas = [];

    this.clienteEmpresaSelecionado = null;

    this.formLogin.controls["cnpj_cpf"].setValue(null);
    this.formLogin.controls["cnpj_cpf"].enable();

    this.formLogin.controls["senha"].setValue(null);

    this.formLogin.reset;
  }

  selecionarClienteEmpresa(guidclientenapis: string) {
    this.clienteEmpresaSelecionado = this.clientesEmpresas.find(clienteEmpresa => clienteEmpresa.guidclientenapis == guidclientenapis);
  }
}

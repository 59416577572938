import { ProdutoPromocional } from './produto-promocional';

export class ProdutoPromocionalAgrupado {
  public bonificacao: boolean;
  public cod_lote: string;
  public referencia_cliente: string;
  public produtoPromocional: ProdutoPromocional[];

  constructor() { }
}

import { trigger, state, style, transition, animate } from '@angular/animations';

export const Animacao = [
  trigger("esvanecerDesvanecer", [
    state("in", style({ opacity: 1 })),

    transition(":enter", [
      style({ opacity: 0 }),
      animate(300)
    ]),

    transition(":leave",
      animate(300, style({ opacity: 0 }))
    )
  ]),
  trigger("desvanecer", [
    transition(":enter", [
      style({ opacity: 0 }),
      animate(400)
    ])
  ])
]

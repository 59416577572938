export class PedidoItens {
  public id: number;
  public order_id: number;
  public product_id: number;
  public quantity: number;
  public unit_price: number;
  public unit_id: number;
  public is_active: boolean;
  public updated_at: string;
  public created_at: Date;
  public seller_id: number;
  public total: number;
  public order_item_id_no_app: number;
  public dt_ultima_atualizacao: Date;
  public id_sinc: number;
  public percentual_desconto: number;
  public preco_tabela: number;
  public descritivo_desconto: string;
  public flag_enviar_celular: string;
  public preco_reposicao: number;
  public ativo_bi: string;
  public dt_faturamento_erp: Date;
  public preco_faturado: number;
  public qtd_faturada: number;
  public id_kit_combo: string;
  public qtd_kit_combo: number;
  public versao_kit_combo: number;
  public id_lote: number;
  public fator_financeiro: number;
  public valor_imposto: number;
  public cod_tipopedvenda: string;
  public descritivo_desconto_especial: string;
  public observacao_item: string;

  public produto_codigo: string;
  public produto_nome: string;
  public referencia_cliente: string;
  public custo: number;
  public grupo_estoque: string;
  public nome_lote: string;
  public cod_lote: string;
  public estoque_lote: number;
  public validade_lote: Date;
  public preco_lote: number;

  constructor() { }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { PedidoItens } from '../../models/pedidos/pedido-itens';
import { PedidoItensSintetico } from '../../models/pedidos/pedido-itens-sintetico';

@Injectable({
  providedIn: 'root'
})

export class PedidoItensService {
  private baseUrl: string;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private toastr: ToastrService
  ) {
    this.baseUrl = baseUrl;
  }

  public add(pedidoItens: PedidoItens[]): Observable<boolean> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "pedidoItens": pedidoItens
    };

    return this.http.post<boolean>(this.baseUrl + "api/PedidoItens/Add", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of(false);
      })
    );
  }

  public getByOrder(order_id: number, seller_id: number): Observable<PedidoItens[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "order_id": order_id,
      "seller_id": seller_id
    };

    return this.http.post<PedidoItens[]>(this.baseUrl + "api/PedidoItens/GetByOrder", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }

  /* Sintético */

  public getB2BByOrder(order_id: number, seller_id: number): Observable<PedidoItensSintetico[]> {
    this.headers.set("Content-Type", "application/json");

    let data = {
      "guidclientenapis": localStorage.getItem("guidclientenapis"),
      "order_id": order_id,
      "seller_id": seller_id
    };

    return this.http.post<PedidoItensSintetico[]>(this.baseUrl + "api/PedidoItens/GetB2BByOrder", data, { headers: this.headers }).pipe(
      catchError(() => {
        this.toastr.error("", "Houve um erro, contate o suporte...");

        return of([]);
      })
    );
  }
}

export class ProdutoDisponibilidade {
  public created_at: Date;
  public client_id_app: number;
  public product_id: number;
  public qtde: number;
  public flag_notificado: boolean;
  public id: number;
  public dt_ultima_atualizacao: Date;
  public produto_nome: string;

  constructor() { }
}

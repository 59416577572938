import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { registerLocaleData, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);

/*  Pacotes Externos */

import { AngularFontAwesomeModule } from 'angular-font-awesome';

import {
  NgbCarouselModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbModalModule,
  NgbTabsetModule,
  NgbTooltipModule,
  NgbTooltipConfig
} from '@ng-bootstrap/ng-bootstrap';

import { ToastrModule } from 'ngx-toastr';

/* Auth */

import { AuthGuard } from './core/guards/auth.guard';
import { AuthService } from './core/services/auth/auth.service';

/* Device */

import { DeviceDetectorService } from 'ngx-device-detector';

/* Listener */

import { ListenerInterceptor } from './core/interceptors/listener-interceptor';
import { ListenerService } from './core/services/listener/listener.service';

/* Components */

import { AppComponent } from './app.component';
import { ConfiguracoesComponent } from './components/configuracoes/configuracoes.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginComponent } from './components/login/login.component';
import { MeusPedidosComponent } from './components/meus-pedidos/meus-pedidos.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ProdutosComponent } from './components/produtos/produtos.component';
import { RedefinirSenhaComponent } from './components/redefinir-senha/redefinir-senha.component';
import { RodapeComponent } from './components/rodape/rodape.component';
import { SortableHeaderDirective } from './diretivas/sortable-header.directive';
import { SuporteComponent } from './components/suporte/suporte.component';
import { TenhoInteresseComponent } from './components/tenho-interesse/tenho-interesse.component';

@NgModule({
  declarations: [
    AppComponent,
    ConfiguracoesComponent,
    LoaderComponent,
    LoginComponent,
    MeusPedidosComponent,
    NavBarComponent,
    NotFoundComponent,
    ProdutosComponent,
    RedefinirSenhaComponent,
    RodapeComponent,
    SortableHeaderDirective,
    SuporteComponent,
    TenhoInteresseComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,

    /* Pacotes Externos */

    AngularFontAwesomeModule,
    NgbCarouselModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbTabsetModule,
    NgbTooltipModule,
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true,
      tapToDismiss: true,
      timeOut: 2400
    })
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    DecimalPipe,

    /* Pacotes Externos */

    NgbTooltipConfig,

    /* Auth */

    AuthGuard,
    AuthService,

    /* Device */

    DeviceDetectorService,

    /* Listener */

    ListenerInterceptor,
    ListenerService,
    { provide: HTTP_INTERCEPTORS, useClass: ListenerInterceptor, multi: true },

    { provide: LOCALE_ID, useValue: "pt" }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { ListenerService } from '../services/listener/listener.service';

@Injectable({
  providedIn: 'root'
})

export class ListenerInterceptor implements HttpInterceptor {
  private qtdeRequisicoes: number = 0;

  constructor(
    private listenerService: ListenerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.reportProgress) {
      this.qtdeRequisicoes++;

      this.listenerService.atualizarRequisicoesPendentes(true);
    }

    return next.handle(req).pipe(
      map(event => {
        return event;
      }),
      finalize(() => {
        if (!req.reportProgress) {
          this.qtdeRequisicoes--;

          this.listenerService.atualizarRequisicoesPendentes(this.qtdeRequisicoes > 0);
        }
      })
    );
  }
}
